import React from "react";
import { useIntl } from "react-intl";

/**
 * The **Price** component is used anywhere a price needs to be displayed.
 *
 * Formatting of prices and currency symbol selection is handled entirely by the ECMAScript Internationalization API available in modern browsers.
 *
 * A [polyfill][] is required for any JavaScript runtime that does not have [Intl.NumberFormat.prototype.formatToParts][].
 *
 * [polyfill]: https://www.npmjs.com/package/intl
 * [Intl.NumberFormat.prototype.formatToParts]: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat/formatToParts
 */

var Price = props => {
  var {
    locale,
    formatMessage
  } = useIntl();
  var {
    value = 0,
    currencyCode,
    addFrom = false,
    gratisIf0 = false
  } = props;
  var formattedValue = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode ? currencyCode : "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }).format(value);
  if (gratisIf0 && value === "0") {
    return "gratis";
  }
  return "".concat(addFrom ? formatMessage({
    id: "from"
  }) : "").concat(formattedValue);
};
export default Price;